import React, { useState, useEffect } from 'react';
import Navbar from './Navbar';
import Sidebar from './Sidebar';
import ComingSoon from './ComingSoon';
import Footer from './Footer';
let { appname, lambda } = window.app;
const Dashboard = () => {
  const [image, setImg] = useState('');

  useEffect(() => {
    // Simulating async setting of window.site
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.common.imageCloudfront && window.site.common.resourcesCloudfront) {
        setImg(window.site.common.imageCloudfront);


      } else {
        setTimeout(checkSiteObject, 50); // Check again after a short delay
      }
    };

    checkSiteObject();
  }, []);
  return (
    <div id="page-top">
  <Navbar />
  <div className="inner-body" id="inner-body">
    <div className="inner-wrapper" id="inner-wrapper">
      <Sidebar id="sidebar" />
      <div className="right-section" id="right-section">
        <div className="page-title" id="page-title">
          <div className='d-flex align-items-center' id="title-container">
            <h1 className="sm-heading lite-text" id="dashboard-title">Dashboard</h1>
          </div>
        </div>

        <div className="content-block Dashboard" id="content-block-dashboard">
          <div className="row" id="dashboard-row">
            {/* Total Albums Card */}
            <div className="col-md-4" id="total-albums-col">
              <div className="card rounded-2 full-ht total_albums" id="total-albums-card">
                <div className="card-body" id="total-albums-card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section" id="total-albums-header">
                    <h3 className="sm-heading bold-txt lite-text font-big-left" id="total-albums-title">Total Albums</h3>
                    <h3 className="sm-heading bold-txt lite-text font-big-right" id="total-albums-count">03</h3>
                  </div>

                  <div className="summary-section" id="total-albums-summary">
                    <div className="d-flex justify-content-between mt-2 mt-1 align-items-center" id="summary-header">
                      <h2 className="sm-heading bold-txt lite-text m-heading" id="summary-title">Summary</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="views-row">
                      <p className="sm-heading lite-text m-0" id="views-label">Views</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="views-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="sharing-row">
                      <p className="sm-heading lite-text m-0" id="sharing-label">Sharing</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="sharing-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="photos-row">
                      <p className="sm-heading lite-text m-0" id="photos-label">Photos</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="photos-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="videos-row">
                      <p className="sm-heading lite-text m-0" id="videos-label">Videos</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="videos-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="devices-row">
                      <p className="sm-heading lite-text m-0" id="devices-label">Devices</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="devices-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="go-to-albums-row">
                      <button className="small-btn" id="go-to-albums-button">Go to Albums</button>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Total Images Card */}
            <div className="col-md-4" id="total-images-col">
              <div className="card half-ht mb-2 total_images" id="total-images-card">
                <div className="card-body" id="total-images-card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section" id="total-images-header">
                    <h3 className="sm-heading bold-txt lite-text font-big-left" id="total-images-title">Total Images</h3>
                    <h3 className="sm-heading bold-txt lite-text font-big-right" id="total-images-count">03</h3>
                  </div>

                  <div className="summary-section" id="total-images-summary">
                    <div className="d-flex justify-content-between mt-2 align-items-center" id="views-images-row">
                      <p className="sm-heading lite-text m-0" id="views-images-label">Views</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="views-images-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="go-to-images-row">
                      <button className="small-btn" id="go-to-images-button">Go to Images</button>
                    </div>
                  </div>
                </div>
              </div>

              {/* Total Videos Card */}
              <div className="card half-ht mb-2 total_images" id="total-videos-card">
                <div className="card-body" id="total-videos-card-body">
                  <div className="d-flex justify-content-between line align-items-center card-section" id="total-videos-header">
                    <h3 className="sm-heading bold-txt lite-text font-big-left" id="total-videos-title">Total Videos</h3>
                    <h3 className="sm-heading bold-txt lite-text font-big-right" id="total-videos-count">03</h3>
                  </div>

                  <div className="summary-section" id="total-videos-summary">
                    <div className="d-flex justify-content-between mt-2 align-items-center" id="views-videos-row">
                      <p className="sm-heading lite-text m-0" id="views-videos-label">Views</p>
                      <h2 className="sm-heading bold-txt lite-text m-2" id="views-videos-count">06</h2>
                    </div>

                    <div className="d-flex justify-content-between mt-2 align-items-center" id="go-to-videos-row">
                      <button className="small-btn" id="go-to-videos-button">Go to Videos</button>
                    </div>
                  </div>
                </div>
              </div>
              
              {/* Storage Block */}
              <div className="card half-ht mb-2 storage_blcok" id="storage-block-card">
                <div className="card-body" id="storage-card-body">
                  <div className="d-flex justify-content-between align-items-center card-section" id="storage-header">
                    <h3 className="sm-heading bold-txt lite-text font-big-left storage" id="storage-title">Storage</h3>
                    <h3 className="sm-heading bold-txt lite-text font-big-right" id="storage-count"></h3>
                  </div>

                  <div className="summary-section" id="storage-summary">
                    <div className="d-flex justify-content-between m-2 align-items-center" id="storage-usage-row">
                      <p className="sm-heading lite-text m-0" id="storage-usage">87.1GB to 128GB</p>
                    </div>

                    <div className="storage-progress" id="storage-progress">
                      <div className="storage-bar" id="storage-bar">
                        <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center" id="full-storage">
                          <span className="material-symbols-outlined full-storage-dot">fiber_manual_record</span> Full Storage
                        </p>

                        <div className="video-bar" id="video-bar">
                          <div className="storage-progress-bar-1" id="video-storage-bar">
                            <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center" id="video-storage-label">
                              <span className="material-symbols-outlined video-dot">fiber_manual_record</span> Videos
                            </p>
                          </div>
                        </div>

                        <div className="image-bar" id="image-bar">
                          <div className="storage-progress-bar-2" id="image-storage-bar">
                            <p className="sm-heading lite-text m-0 d-flex justify-content-center align-items-center" id="image-storage-label">
                              <span className="material-symbols-outlined image-dot">fiber_manual_record</span> Images
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Footer */}
            <Footer id="footer" />
          </div>
        </div>
      </div>
    </div>
  </div>
</div>


  )
}
export default Dashboard;