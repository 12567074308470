import React, { useEffect, useState, useContext } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import Loader from './Loader'
import { CommonContext } from './CommonContext';
let { appname, lambda } = window.app;

const Sidebar = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [albumStatus, setalbumStatus] = useState('');
  const { userData, setUserData } = useContext(CommonContext);
  const { navOpen, setNav } = useContext(CommonContext);
  const [activeLoad, setActiveLoad] = useState(false);
  const isActive = (path) => location.pathname === path ? 'active' : '';
  const [ menusList, setMenus ] = useState([]);


  useEffect(() => {
    var text = location.pathname;
    var term = "album";


    if (text.indexOf(term) != -1)
      setalbumStatus('active')

  }, []);

  useEffect(() => {
    // console.log('navOpensss ', navOpen)

  }, [navOpen]);

  useEffect(() => {
    const checkSiteObject = () => {
      if (window.site && window.site.common && window.site.webmenus) {
        setMenus(window.site.webmenus)
      } else {
        setTimeout(checkSiteObject, 50);
      }
    };
    checkSiteObject();
  }, []);


  useEffect(() => {
    if (!localStorage.getItem("token")) {
      navigate("/");
    } else {
      fetchUserData();
    }
  }, [navigate]);

  const fetchUserData = async () => {
    setActiveLoad(true)
    try {
      const clientid = localStorage.getItem('clientid');
      const token = localStorage.getItem('token');
      const response = await axios.get(`${lambda}/client?appname=${appname}&clientid=${clientid}`);

      if (response.data?.result === "Client not found") {
        localStorage.removeItem("token");
        localStorage.removeItem("currentSessionClientTime");
        localStorage.removeItem("clientid");
        navigate('/signin');
      } else if (response.status === 200 && response.data?.result?.[0]) {
        setUserData(response.data.result[0]);
        setActiveLoad(false)
      } else {
        setActiveLoad(false)
        throw new Error('Unexpected response format');
      }
    } catch (err) {
      setActiveLoad(false)
      console.error('Error fetching user data:', err);
    }
  };

  const handleNavigation = (path) => {
    navigate(path);
    setNav(!navOpen);
  };

  const formatBytes = (bytes, decimals = 2) => {
    if (bytes === 0) return '0 Bytes';
    const k = 1024;
    const dm = decimals < 0 ? 0 : decimals;
    const sizes = ['Bytes', 'KB', 'MB', 'GB'];
    const i = Math.floor(Math.log(bytes) / Math.log(k));
    return parseFloat((bytes / Math.pow(k, i)).toFixed(dm)) + ' ' + sizes[i];
  };

  const { usedStorage = 0, totalStorage = 0, remainingStorage = 0 } = userData || {};
  let storageUsed = usedStorage > 0 ? usedStorage : 0

  // console.log('menusList ', menusList)

  return (
    <div id="leftNav" className={navOpen ? "left-nav mobile_nav" : "left-nav"}>
    <ul id="menuList">
    {menusList.length > 0 && menusList.map((item, index) => (
        item.enable && 
        <li id={`menuItem-${item.route}`} className={isActive('/' + item.route)} onClick={() => handleNavigation('/' + item.route)} key={index}>
           <span id={`icon-${item.route}`} className="material-symbols-outlined me-3">{item.icon}</span>
           <span id={`menuText-${item.route}`} className="md-txt">{item.menu}</span>
         </li>
        ))}

    </ul>

    {activeLoad ? <Loader id="loader" /> :
      <div id="storageBlock" className="storage-block">
        <div id="storageHeader" className="storage-header flex-spread">
          <div id="storageTitle" className="storage-title">
            <span id="cloudIcon" className="material-symbols-outlined me-1">cloud</span>
            <h6 id="storageTitleText">Storage</h6>
          </div>
          {userData && <h6 id="storagePercent" className="store-percent">{usedStorage > 0 ? ((storageUsed / totalStorage) * 100).toFixed(2) : "0.00"}%</h6>}
        </div>
        <div id="storageBody" className="storage-body">
          <div id="storageProgress" className="storage-progress">
            <div id="storageBar" className="storage-bar">
              <div
                id="storageProgressBar"
                className="storage-progress-bar"
                style={{
                  width: `${(storageUsed / totalStorage) * 100}%`
                }}
              ></div>
            </div>
          </div>
          {userData && <p id="storageUsed" className="storage-used">
            <span id="usedStorageText" className="bold-txt">{formatBytes(storageUsed ?? 0)}</span> of {formatBytes(totalStorage ?? 0)}
          </p>}
        </div>
      </div>
    }
</div>

  );
};

export default Sidebar;
